.main {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  width: 100%;
  min-height: 300px;
  > section {
    align-items: center;
  }
  .step {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 450px;
    text-align: center;
    width: 100%;
    align-self: center;
    img,
    svg {
      height: 135px;
      width: 135px;
      border-radius: 67.5px;
    }

    img {
      box-shadow: var(--card-elevation);
    }
    .approved {
      height: 135px;
      width: 135px;
    }
  }
}

.pagination {
  margin: 0px auto;
}

.actions {
  align-self: center;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
  max-width: 400px;
  > :first-child {
    flex: 1;
  }
  > :last-child {
    flex: 2;
  }
}

.title {
  align-self: flex-start;
}