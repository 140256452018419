$breakInto: 950;
@value breakInto #{$breakInto};

.parentRoot {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 16px 16px;
  z-index: 1000000;
  background-color: var(--glass-bg);
  p,
  input {
    color: var(--text-color);
    white-space: nowrap;
  }
  &.remounted {
    animation-name: enter;
    animation-duration: 500ms;
    animation-timing-function: ease-out;
  }
}

@keyframes enter {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0%);
  }
}

.row {
  display: flex;
  cursor: pointer;
  align-items: center;
  > :last-child {
    margin-left: 12px;
  }
}

.root {
  justify-content: stretch;
  width: 100%;
  max-width: var(--container-max-width);
}

.root > *,
.content {
  display: flex;
  flex-direction: row !important;
  align-items: center;
}

.content {
  flex: 1;
  .tabLabel,
  input {
    font-family: var(--secondary-font);
    font-size: 14px !important;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 1px;
    text-align: center;
  }
  .tabLabel {
    cursor: pointer;
    &:hover {
      font-weight: bold;
    }
    margin-right: 24px;
  }
}
.select {
  input {
    width: 96px !important;
    margin-bottom: 0px !important;
    border: 0px !important;
  }
}
.login {
  display: flex;
  align-items: center;
}
.logo {
  z-index: 10;
  cursor: pointer;
}

.dropdown {
  width: auto !important;
  padding-left: 40px;
  padding-right: 16px;
  padding-bottom: 16px;
  background-color: #0000 !important;
  .avatarMenuItem {
    display: flex;
    align-items: center;
    color: var(--default-text-color);
    --svg-color: var(--default-text-color);
    padding: 16px;
    padding-right: 48px;
    margin-bottom: 14px;
    transition: margin-left 250ms linear, padding-right 250ms linear;
    margin-left: (0px);
    background-color: var(--secondary-background);
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;

    &:first-child {
      margin-top: 14px;
    }
    font-size: 1.5em;
    cursor: pointer;
    svg {
      margin-right: 14px;
      height: 1em;
    }
    &:hover,
    &.selected {
      background-color: var(--digital-blue);
      color: var(--digital-blue-contrast);
      --svg-color: var(--digital-blue-contrast);
      padding-right: 88px;
      margin-left: (-40px);
      svg {
        stroke: var(--digital-blue-contrast);
      }
    }
  }
}

/** MOBILE */
@media screen and (max-width: #{$breakInto - 1px}) {
  .themeChanger {
    margin-right: 64px;
    display: flex;
  }
  .content {
    backdrop-filter: var(--glass-backdrop);
  }
  .parentRoot {
    z-index: 1000000;
    backdrop-filter: var(--glass-backdrop);
    &.open {
      backdrop-filter: var(0px);
      background-color: #0000;
    }
  }
  .root .logo {
    margin-left: 24px;
    margin-right: auto;
    margin-top: 4px;
  }
  .content {
    &,
    .tabs {
      flex-direction: column !important;
    }
    align-items: flex-start;
    bottom: 0px;
    background-color: var(--glass-bg);
    backdrop-filter: var(--glass-backdrop);
    padding: 34px;
    padding-top: 96px;
    z-index: 10000 !important;
    button {
      display: inline-block;
    }
  }
  .select {
    margin-top: auto;
  }
  .control {
    position: fixed;
    right: 32px;
    top: 32px;
  }
}
/** DESKTOP */
@media screen and (min-width: #{$breakInto}px) {
  .parentRoot {
    backdrop-filter: var(--glass-backdrop);
  }

  .select {
    margin-left: auto;
  }
  .content {
    margin-left: 40px;
    align-items: center;
  }
}

@media screen and (max-width: 840px) {
  .row > :last-child {
    margin-left: 6px;
  }
  .content {
    margin-left: 0px;
    .tabLabel {
      margin-right: 12px;
    }
  }
}
