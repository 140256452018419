.root {
  flex: 1;
  display: flex;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}

.paginable {
  align-self: center;
}

.parentRoot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
