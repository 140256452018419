.root {
  overflow: auto;
  > * {
    display: flex;
    flex-direction: column;
    button {
      align-self: center;
    }
  }
}
.buttons {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  button {
    min-width: 150px;
  }
  &:not(.single) {
    button:first-child {
      flex: 1;
    }
    button:last-child {
      flex: 2;
    }
  }
}
.alert {
  display: flex;
  background-color: var(--alert-bg);
  border-radius: 8px;
  padding: 22px;
  @media screen and (max-width: 500px) {
    padding: 12px;
  }
  > :first-child {
    margin-right: 18px;
  }
}

.claimConfirmation {
  align-items: stretch !important;
}
