.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  > :not(:first-child) {
    width: 100%;
  }
  > :first-child {
    margin-right: auto;
  }

  .section {
    padding: 48px 0px;
    > :first-child {
      max-width: 800px;
      margin: auto;
    }
    width: 100%;
    &:nth-child(2n-1) {
      background-color: var(--secondary-background);
    }

    &.state {
      display: flex;
      flex-direction: column;
    }
  }
  .box {
    background-color: var(--main-bg);
    padding: 48px;
    max-width: 550px;
    min-height: 250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    width: 100%;
    text-align: center;
  }
  .challengeDetails {
    &,
    .requirementsList {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
    .requirementsList {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 24px;
      .requirement {
        & > :first-child,
        img {
          border-radius: 24px;
        }
        overflow: hidden;
        max-width: 210px;
        display: flex;
        flex-direction: column;
        align-items: center;
        > :first-child > :last-child {
          padding: 12px 18px;
          > :last-child {
            display: flex;
            align-items: center;
          }
        }
        &.complete > :first-child {
          background: linear-gradient(
            147.49deg,
            #312f3a 5.49%,
            #9747ff 134.58%
          );
        }
        &.incomplete > :first-child {
          background: #373737;
        }
      }
    }
    position: relative;
    .overlay {
      position: absolute;
      width: 100%;
      height: calc(100% + 48px);
      margin-top: -24px;
      backdrop-filter: blur(8px);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      z-index: 1;
      text-align: center;
      > :first-child {
        background-color: var(--glass-bg);
        padding: 48px 72px;
        border-radius: 16px;
        box-shadow: var(--card-elevation);
      }
    }
  }
}

.claiming {
  height: 548px;
  margin-bottom: -48px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  > * {
    height: 1em;
    width: 1em;
    position: absolute;
    transition: bottom, left;
    border-radius: 0.5em;
    > * {
      width: inherit;
      height: inherit;
      border-radius: inherit;
      vertical-align: top !important;
      object-fit: cover;
      -webkit-user-drag: none;
    }
    &.bg {
      filter: blur(8px);
    }
    &.fg {
      filter: blur(1px);
    }
  }
}

.modal {
  > section {
    padding: 0px;
  }
}
