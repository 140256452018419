.row {
  display: flex;
  align-items: center;
  gap: 12px;
}
.content {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 22px;
}
.divider {
  width: 100%;
  margin: 0px !important;
  margin-bottom: 28px !important;
  margin-top: 14px !important;
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 14px;
}
.replies {
  margin-left: 24px;
  padding-left: 24px;
  border-left: 1px solid var(--text-default-color);
}