/* Lexend */
@font-face {
  font-family: 'Lexend';
  src: url('../../src/playground/assets/fonts/Lexend-Regular.ttf')
    format('truetype');
}
/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  src: url('../../src/playground/assets/fonts/OpenSans-Regular.ttf')
    format('truetype');
}

/* TAN Nimbus */
@font-face {
  font-family: 'TAN Nimbus';
  src: url('../assets/fonts/TanNimbus/TAN-NIMBUS.otf') format('opentype');
}
