@use 'sass:math';

@import './GalleryCard.variables.scss';

$_250ms: var(--animation--speed-fast, 250ms);
$defaultWidth: map-get(
  $map: $baseWidthPxUnits,
  $key: 'default',
);

.root {
  display: flex;
  margin-right: 26px;
  box-shadow: var(--card-elevation);
  border-radius: 16px;
  .assets {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-size: cover;
    background-position: center;

    padding: #{$assetsListInnerPadding}px;
    flex-shrink: 0;
  }
  > :last-child {
    &,
    > * {
      align-items: stretch;
    }
  }
  .gallery {
    cursor: pointer;
    height: #{$defaultWidth}px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    width: #{$defaultWidth}px;
    min-width: #{$defaultWidth}px;
    border-radius: 16px;
    overflow: hidden;
    min-height: #{$defaultWidth}px;
    transition: border-radius $_250ms linear, min-width $_250ms linear,
      min-height $_250ms linear;
    box-shadow: var(--card-elevation);
    position: relative;
    background-image: var(--bg-image);
    &::before,
    &::after {
      content: ' ';
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      transition: opacity 125ms linear, background-color 150ms linear;
      opacity: 0;
      height: 436px;
      pointer-events: none;
    }
    &::before {
      background-image: linear-gradient(
        to top,
        var(--predominant-color, black),
        #fff0
      );
    }
    &::after {
      background-color: var(--predominant-color, white);
      height: 100%;
    }

    .galleryInfo,
    .tokenEmitterResume {
      align-self: stretch;
      min-height: 42.38%;
      padding-top: 0%;
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      transition: padding-top $_250ms ease-in, min-height $_250ms linear,
        background-color 150ms linear;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      z-index: 1;
      &::before,
      &::after {
        content: ' ';
        flex: 1;
        transition: flex 250ms linear;
      }
      > * {
        text-align: center;
        transition: font-size $_250ms linear, line-height $_250ms linear,
          color $_250ms linear;
        margin: 0px 16px;
      }
    }
    &.expanded > :last-child {
      background-color: var(--predominant-color-light, white);
    }
  }

  &.default,
  &.banner {
    .gallery {
      > .icon {
        height: 18%;
        width: 18%;
        max-height: 96px;
        max-width: 96px;
        margin-bottom: -9%;
        z-index: 2;
        transition: height $_250ms linear, width $_250ms linear,
          margin-bottom $_250ms ease-in;
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;
      }
    }
  }

  &.cvm88 {
    $galleryWidth: map-get(
      $map: $baseWidthPxUnits,
      $key: 'cvm88',
    );
    .gallery {
      width: #{$galleryWidth}px;
      min-width: #{$galleryWidth}px;
      min-height: 222px;
      height: 222px;
      .cvm88Heading {
        width: 100%;
        z-index: 1;
        background-color: #fff9;
        opacity: 1;
        > * {
          display: flex;
          gap: 20px;
          align-items: center;
          padding: 24px;
        }
        img {
          height: 80px;
          width: 80px;
        }
      }
      .galleryInfo {
        background-color: #0000;
      }
      .tokenEmitterResume {
        opacity: 1;
        transition: padding-top $_250ms ease-in, min-height $_250ms linear,
          max-height $_250ms linear, height $_250ms linear,
          background-color 150ms linear, opacity 150ms linear !important;
        min-height: initial;
      }
    }
    &[data-hero-clone] {
      .tokenEmitterResume,
      .cvm88Heading {
        position: absolute;
        left: 0px;
        right: 0px;
      }
      .tokenEmitterResume {
        bottom: 0px;
      }
      .cvm88Heading {
        top: 0px;
        bottom: 0px;
        height: 100% !important;
        max-height: 100% !important;
      }
    }
  }

  &.readyForTransition {
    padding: 32px 0px;
    .gallery {
      $iconSize: $defaultWidth * 0.18 * 1.8;
      $halfHeight: math.div($defaultWidth, 2);
      > :first-child {
        height: #{$iconSize}px;
        width: #{$iconSize}px;
        margin-bottom: -#{$halfHeight}px;
      }
      > :last-child {
        height: 100%;
        padding-top: #{$halfHeight}px;
        border-bottom-right-radius: 0px;
      }
    }
  }
  > :last-child {
    opacity: 1;
  }
  &.banner {
    border-radius: 0px;
    .gallery {
      min-width: 100vw;
      cursor: initial;
      min-height: 436px;
      border-radius: 0px;

      &::before {
        opacity: 1;
      }
      .icon {
        height: 18% * 1.8;
        width: 18% * 1.8;
        margin-bottom: 0%;
        transition: margin-bottom 105ms linear, height $_250ms linear,
          width $_250ms linear;
      }
      .galleryInfo {
        min-height: 228px;
        padding-top: 2%;
        background-color: #fff0;
        transition: padding-top 105ms linear, height 125ms linear,
          background-color 150ms linear, min-height 125ms linear;
        * {
          color: white;
        }
        > :first-child {
          font-size: 48px;
          line-height: 56px;
        }
        > :last-child {
          font-size: 16px;
          line-height: 24px;
        }
        &::before {
          flex: 0;
        }
      }
    }
    > :last-child {
      opacity: 0;
    }
  }
  .scrollable > * {
    align-items: flex-start;
    transition: width 1s, height 1s;
    > * {
      width: 100%;
    }
  }

  .gallery.dynamic {
    height: auto;
  }
  .expanded.dynamic {
    &::after {
      opacity: 1;
    }
    > :last-child {
      max-height: #{$defaultWidth}px;
    }
  }
  .assets {
    background-image: var(--bg-image);
  }
  &.v {
    flex-direction: column;
    .assets {
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .gallery.expanded {
      &,
      :last-child {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
  &.h {
    .assets {
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
    }
    .gallery.expanded {
      &,
      :last-child {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
      }
    }
  }
}

.root.v2 {
  background-image: var(--bg-image);
  background-size: cover;
  background-position: center;
  position: relative;
  border-radius: 18px;
  margin-right: 18px;
  align-items: center;
  &.readyForTransition::before,
  &.banner::before {
    content: ' ';
    background-color: var(--text-contrast-to-gallery);
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    backdrop-filter: blur(8px);
    transition: background-color $_250ms linear, backdrop-filter $_250ms linear;
  }
  &.readyForTransition {
    overflow: hidden;
    margin-right: 0px;
  }
  &.banner {
    margin-right: 0px;
    padding: 0px;
    border-radius: 0px;
    .gallery {
      border: none;
    }
    &::before {
      background-color: #0000;
      backdrop-filter: blur(0px);
    }
  }
  .gallery {
    background-image: none;
  }
  .gallery.expanded {
    & > :last-child {
      background-color: transparent;
    }
  }
  &:not(.readyForTransition) .gallery > * {
    --text-default-color: var(--text-contrast-to-gallery);
  }
  &.readyForTransition .gallery {
    background-image: none;
    border: 0px;
    box-shadow: none;
  }
  .assets {
    background-image: none;
  }
  &.readyForTransition .gallery > :last-child {
    background-color: #0000;
  }
}

@media screen and (max-width: 450px) {
  .root .assets {
    padding: #{math.div($assetsListInnerPadding, 8)}px;
  }
  .root.v2.readyForTransition {
    margin-right: 0px;
  }
}
