$creatorColor: rgba(255, 122, 0);
$brandColor: rgb(160, 192, 239);

@value BrandColor #{$brandColor};
@value CreatorsColor #{$creatorColor};

.creators {
  --theme-color: #{$creatorColor};
  --theme-color-opacity: rgba(255, 123, 0, 0.38);
}
.brand {
  --theme-color: #{$brandColor};
  --theme-color-opacity: rgba(160, 192, 239, 0.38);
}

.tabContainer {
  flex-wrap: wrap;
  justify-content: center;
}

.labelProfile {
  margin-bottom: 8px;
}

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  .collapsable {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    opacity: 1;
    transition: height var(--animation--speed-fast) linear;
    overflow: hidden;
    > * {
      flex-shrink: 0;
    }
    button {
      margin: auto;
    }
  }

  > .title {
    max-width: 480px;
    text-align: center;
  }

  > * {
    z-index: 1;
  }
  .toggler {
    padding: 12px;
    border-radius: 3.2em / 50%;
    background-color: #00000033;
    --tab-guide-color: var(--text-default-color);
    --tab-selected-color: var(--main-bg);
    .tab {
      width: 300px;
      text-align: center;
    }
  }

  .bgTransition {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 0;
    background-color: var(--theme-color);
    transition: background-color var(--animation--speed-fast) linear;
    &,
    div {
      height: 100%;
      width: 100%;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    }
    &::after,
    &::before {
      content: ' ';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      opacity: 0.7;
    }
    &::after {
      background: linear-gradient(to top, var(--theme-color), #0000);
    }
    &::before {
      background: linear-gradient(to bottom, var(--theme-color), #0000);
      transition: opacity var(--animation--speed-fast) linear;
      z-index: 1;
    }
  }
  &.heading .bgTransition::before {
    opacity: 0;
  }
  &.page .bgTransition::before {
    opacity: 0.7;
  }
  &.heading {
    border-radius: 0px;
  }
  &.page {
    border-radius: 16px;
  }
  section {
    width: 100%;
  }
  .creatorCards {
    align-items: flex-start;
    .cases {
      display: flex;
      justify-content: center;
      gap: 24px;
      flex-wrap: wrap;
    }
  }
  .back {
    margin-right: auto;
  }
}

.creatorCard {
  width: 348px;
  height: 380px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: var(--card-elevation);
  padding: 78px 32px;
  text-align: center;
  position: relative;
  background-size: cover;
  border-radius: 16px;
  overflow: hidden;
  &::before {
    content: ' ';
    background: linear-gradient(
      to top,
      #0000,
      var(--theme-color-opacity) 75%,
      var(--theme-color)
    );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }
  > * {
    z-index: 1;
  }
}

.brand .creatorCard > :last-child {
  max-width: 250px;
}
.creators .creatorCard > :last-child {
  max-width: 200px;
}
