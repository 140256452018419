header.head {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding: 54px 0px;

  .title {
    margin-bottom: 29px;
  }
  .description {
    text-align: center;
    max-width: 350px;
  }

  svg {
    height: 35px;
    flex-shrink: 0;
  }

  &.DETAILS {
    height: 540px;
    justify-content: center;
    max-width: 420px;
    border-radius: 16px;
    box-shadow: var(--card-refraction);
    @media screen and (max-width: 640px) {
      height: auto;
      width: 100%;
    }
  }
  &.FEATURE_CARD {
    height: 264px;
    justify-content: center;
  }
  &.SMALL {
    border-radius: 16px;
    height: 240px;
    justify-content: center;
    width: 350px;
  }

  &.transitioning svg {
    opacity: 0;
    visibility: visible;
  }
}
