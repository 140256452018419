.controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  > :last-child {
    display: flex;
  }
}
.btns {
  button {
    transition: filter 250ms linear;
    min-width: initial;
    padding: 0px;
    svg {
      display: initial !important;
    }
    > * {
      margin: 0px;
    }
    &:first-child {
      padding-right: 8px;
      padding-left: 0px;
    }
    &:last-child {
      padding-left: 8px;
      padding-right: 0px;
    }
  }
  button:disabled {
    filter: grayscale(1) blur(2px);
  }
}
