body {
  --container-max-width: 1416px;
}

.root {
  max-width: var(--container-max-width);
  margin: 0px auto;
  padding: 0px 8px;
  width: 100%;
}

.size_800 {
  --container-max-width: 800px;
}
