.logo {
  position: absolute;
  top: -25vw;
  right: -10vw;
  height: 50vw;
  min-width: 350px;
  min-height: 350px;
  z-index: -1;
}

.galleriesList {
  display: flex;
  margin-top: 42px !important;
  > * {
    margin-bottom: 40px;
    width: 100%;
  }
  margin-bottom: 72px;
}
.card {
  margin-bottom: 40px;
}

.title {
  margin-top: 64px;
}

.sectionTitle {
  margin-top: 48px;
}

.galleryContainer {
  flex: 1;
}

.perspective > * {
  perspective: 2000px;
  overflow: visible !important;
}

.items {
  overflow: visible;
}

@media screen and (orientation: portrait) {
  .orderableGalleries > * > * {
    justify-content: space-around;
    margin-right: 0px !important;
  }
}
