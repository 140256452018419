@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

/* Sticky footer styles. Use class fullHeight in outer containing divs */

html,
body,
#root,
.fullHeight {
  height: 100%;
}

.fullHeight {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

/* End of Sticky footer styles */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p.firebase-emulator-warning {
  top: 0;
  height: 30px;
  width: 200px !important;
  overflow: hidden;
  display: none;
}
