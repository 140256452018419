.root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  --svg-color: var(--text-default-color);
  margin-bottom: 150px;
  margin-top: 48px;
  svg {
    width: 150px;
  }
}
