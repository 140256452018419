.link {
  text-decoration: underline;
}
.root {
  padding: 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  .divider {
    align-self: stretch;
    margin: 12px -24px;
  }
  > div:last-child {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-self: stretch;
  }
  .check {
    align-items: flex-start;
    > :last-child {
      padding: 13px 0px;
    }
    > :last-child span div {
      margin-top: 1em;
      font-size: 0.75em;
      line-height: 1.5em;
    }
  }
}
