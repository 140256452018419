.brand .description {
  max-width: 660px;
}
.creators .description {
  max-width: 820px;
}

.description {
  text-align: center;
  margin: auto;
}

.content {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  gap: 38px;
  align-items: center;
  @media screen and (max-width: 650px) {
    flex-wrap: wrap;
  }
}
.modules {
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.proposition {
  padding-inline-start: 1em;
  margin: 0px;
  li {
    margin-bottom: 24px;
  }
}

.transition {
  align-items: flex-start;
}

.tooltip {
  --tooltip-background-color: var(--secondary-background) !important;
  > :first-child {
    padding: 12px 24px;
    max-width: 136px;
    box-shadow: none;
    border-radius: 8px !important;
  }
}
