.parentRoot {
  display: flex;
  flex-wrap: wrap;
  gap: 24px 0px;
  justify-content: space-between;
}
@mixin for-desktop-width {
  @media (min-width: 540px) {
    @content;
  }
  :global(.static) & {
    @content;
  }
}

.coverImg {
  height: 200px;
  max-height: 200px;
  object-fit: cover;
  width: 100%;
  object-fit: cover;
  transition: border-radius 250ms linear;
  border-radius: 16px;
  @include for-desktop-width() {
    height: 140px;
    max-height: 140px;
    height: initial;
    width: initial;
  }
}
.benefitItemContainer {
  &.clickable {
    cursor: pointer;
  }
  align-self: stretch;
  width: 100%;
  @include for-desktop-width() {
    min-width: 500px;
    max-width: 48%;
  }
  transition: opacity 250ms linear;
  .root {
    flex-direction: column;
    padding-right: initial;
    .coverImg {
      border-radius: 0px;
      @include for-desktop-width() {
        height: 100%;
        width: auto;
      }
    }
    @include for-desktop-width() {
      padding-right: 24px;
      flex-direction: row !important;
    }
    &.expired {
      filter: grayscale(1);
    }
    &.claimed {
      --digital-blue: var(--ok-color);
    }
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    background: var(--benefits-card-bg);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    overflow: hidden;
    > :nth-child(2) {
      flex: 1;
      padding: 24px;
    }
    .col {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    button {
      width: 100%;
      max-width: 160px;
      white-space: normal;
      > * > * {
        flex: 1;
      }
    }
    .icon {
      background-color: var(--ok-color);
      height: 32px;
      border-radius: 16px;
      margin-right: 8px;
      svg {
        width: 16px;
        margin: 8px;
      }
    }
    .action {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 150px;
      > p:not(:last-child) {
        color: #0000;
        user-select: none;
      }
      > :not(button) {
        font-weight: bold;
        font-family: var(--secondary-font);
      }
    }
  }
}
.animating {
  z-index: 100000000;
  .action {
    button,
    p:first-child {
      overflow: hidden;
      animation-name: shrink;
      animation-duration: 250ms;
      animation-fill-mode: forwards;
      animation-timing-function: linear;

      @keyframes shrink {
        0% {
          max-height: 100px;
        }
        100% {
          max-height: 0px;
        }
      }
    }
    button {
      animation-name: shrinkBtn;
      @keyframes shrinkBtn {
        0% {
          max-height: 100px;
          opacity: 1;
        }
        100% {
          max-height: 0px;
          padding-top: 0px;
          padding-bottom: 0px;
          opacity: 0;
        }
      }
    }
  }
}
.heroEls {
  z-index: 100000000;
}

.detailsContainer {
  height: 470px;
  align-items: stretch;

  > :last-child {
    > div {
      @media screen and (orientation: portrait) {
        max-height: 200px;
      }
      overflow: auto;
    }
  }
  @media screen and (orientation: landscape) {
    display: flex;
    > :first-child {
      flex: 1;
    }
    > :last-child {
      flex: 3;
      padding-left: 24px;
      margin-left: 24px;
      border-left: 1px solid #f2f5f9;
      > div {
        overflow: auto;
        height: 100%;
      }
    }
  }
}
