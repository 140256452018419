.container {
  margin-top: 40px;
  margin-bottom: 72px;
}

.back {
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  margin-top: 24px;
  * {
    color: white;
  }
  svg {
    fill: white;
  }
}

.noItems {
  text-align: center;
  margin: auto;
  padding: 72px 0px;
}