.tokenEmitterResume {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 24px;
  align-items: flex-end;
  > :first-child {
    text-align: left;
  }
  > :last-child {
    text-align: right;
    img {
      height: 32px;
      width: 32px;
      border: 2px solid var(--primary-color);
      border-radius: 8px;
      position: relative;
      &:not(:last-child) {
        margin-right: -12px;
      }
    }
  }
}
