.actions {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  button {
    min-width: 260px;
  }
}

.transitioner {
  align-items: flex-start;
}

.formSection {
  &.WRITE {
    > :last-child > div {
      max-width: 300px;
    }
  }
  .acceptInput {
    background-color: var(--secondary-background);
    box-shadow: var(--card-elevation);
    border-radius: 8px;
    > div {
      padding: 0px 24px;
    }
    > :first-child {
      padding: 32px 24px 24px 24px;
      > :nth-child(3) {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        text-decoration: underline;
        line-height: 20px;

        > :first-child {
          width: 44px;
          height: 44px;
          background-color: var(--main-bg);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 22px;
          margin-right: 14px;
          svg {
            height: 20px;
          }
        }
      }
    }
    > :last-child {
      padding: 24px;
      border-bottom-right-radius: 8px;
      border-bottom-left-radius: 8px;
      border: 1px solid #d0d0d0;
      background-color: var(--main-bg);
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}

.card,
.acceptInput {
  max-width: initial !important;
}
