.badge {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  padding: 8px;
  margin-bottom: 14px;
  text-align: center;
  box-shadow: var(--card-elevation);
  transition: box-shadow 250ms linear;
  &.INCOMPLETE {
    background-color: var(--main-bg);
  }
  &.ERROR {
    background-color: red;
    color: white;
  }
  &.COMPLETED,
  &.CURRENT {
    background-color: var(--digital-blue);
    color: var(--digital-blue-contrast);
  }
  &.CURRENT {
    box-shadow: var(--card-elevation-x2);
  }
}
.headings {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  background-color: var(--secondary-background);
  padding: 30px 40px;
  border-radius: 8px;
  > * {
    max-width: 130px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &.mobile {
    position: sticky;
    top: -1px;
    align-items: stretch;
    flex-direction: column;
    z-index: 1;
    padding: 0px;
    gap: 0px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    > * {
      max-width: initial;
    }
    > :last-child {
      padding: 16px;
      display: block;
    }
    .stepContainer {
      display: flex;
      align-items: center;
      gap: 16px;
      > :last-child {
        margin-left: auto;
      }
    }
    .badge {
      margin-bottom: 0px;
    }
  }
}
