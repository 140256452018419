.label {
  max-width: 324px;
  opacity: 0;
  * {
    font-weight: bold;
  }
}

.root {
  display: flex;
  width: 100%;
  --svg-color: var(--text-default-color);
  > :first-child {
    flex: 1;
    .line {
      margin-left: -20px;
      max-height: 1em;
      width: calc(100% + 30px);
    }
  }
  .content {
    padding-right: 30px;
    opacity: 0;
  }
  &.noAnimation .content {
    opacity: 1;
  }
}

.animate .label,
.animate .content {
  animation-name: growAndAppear;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  animation-duration: 2s;
  @keyframes growAndAppear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
.animate .label {
  animation-delay: 500ms;
}

.content > .hiddenMd {
  display: none;
}
@media screen and (max-width: 640px) {
  .label {
    display: none;
  }
  .content > .hiddenMd {
    display: block;
  }
}
