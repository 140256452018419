@use 'sass:math';
@import '@onepercentio/one-ui/dist/assets/styles/variables.scss';

@value open from "@onepercentio/one-ui/dist/components/AdaptiveDialog/AdaptiveDialog.module.scss";
@value close from "@onepercentio/one-ui/dist/components/AdaptiveDialog/AdaptiveDialog.module.scss";
@value backdrop from "@onepercentio/one-ui/dist/components/AdaptiveDialog/AdaptiveDialog.module.scss";
@value closeBtn from "@onepercentio/one-ui/dist/components/AdaptiveDialog/AdaptiveDialog.module.scss";

.zIndexIncrease {
  z-index: 1000000 !important;
}
.zIndexIncrease2 {
  z-index: 10000000 !important;
}

.adaptiveDialogBackdrop:not(:global(.custom)) {
  .adaptiveDialogRoot {
    background-color: var(--main-bg);
    border-radius: 16px;
    align-items: flex-start;
    padding: 40px;
  }
  .closeBtn {
    top: 40px;
    right: 40px;
    z-index: 1;
  }
}
.adaptiveDialogBackdrop.custom {
  padding: 24px;
  padding-bottom: 0px;
  justify-content: flex-end;
  align-items: stretch;
  animation-duration: 500ms;
  .adaptiveDialogRoot {
    --bg-color: var(--adaptive-dialog-bg);
    border-radius: 24px 24px 0px 0px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    max-height: initial !important;
    background-image: var(--bg-image);
    background-size: 100% 200vw;
    background-position: 0px -1660px;
    background-color: var(--glass-bg);
    animation-fill-mode: forwards;
    animation-duration: 750ms;
    animation-delay: 100ms;
    max-width: 981px;
    hr {
      margin: 0px -32px;
    }

    > :global(.closeBtn) {
      z-index: 1;
      background-color: var(--glass-bg);
      padding: 36px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      box-shadow: var(--card-elevation);
      cursor: pointer;
      --scale: scale(1.6);
      transition: transform $fast linear;

      &:hover {
        transform: scale(0.8);
      }

      > * {
        transform: var(--scale);
      }
      &::before {
        transform: var(--scale);
        position: absolute;
        height: 28px;
        width: 28px;
        border-radius: 14px;
        content: ' ';
        border: math.div(24px, 7) solid var(--text-default-color);
      }

      @media screen and (max-width: 640px) {
        padding: 8px !important;
        border-radius: math.div(20px, 4);
        --scale: 0.6;
        &::before {
          border: math.div(24px, 12) solid var(--text-default-color);
        }
      }
    }

    @keyframes dialogAppear {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
  @media screen and (orientation: landscape) {
    padding-bottom: 24px;
    .adaptiveDialogRoot {
      animation-name: dialogAppear;
      opacity: 0;
      border-radius: 24px 24px 24px 24px;
    }

    &:global(.close):global(.backdrop) {
      background-color: var(--digital-blue-alpha);
      animation-delay: 250ms;
      > :first-child {
        animation-delay: 0ms;
        animation-name: backdropDismiss;
      }
    }
  }

  &:global(.open) {
    animation-name: backdropAppear;
  }
  &:global(.close):global(.backdrop) {
    animation-duration: 850ms;
    background-color: var(--digital-blue-alpha);
  }

  @keyframes backdropDismiss {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  @keyframes backdropAppear {
    0% {
      background-color: #0000;
    }
    100% {
      background-color: var(--digital-blue-alpha);
    }
  }
}

.title_v2 {
  font-family: var(--secondary-font);
  font-size: 40px;
  font-weight: bold;
  line-height: 50px;
}

.p40_v2 {
  font-family: var(--secondary-font);
  font-size: 40px;
  line-height: 50px;
}

.p20_v2 {
  font-family: var(--secondary-font);
  font-size: 20px;
  line-height: 25px;
  font-weight: normal;
}

.p30_v2 {
  font-family: var(--secondary-font);
  font-size: 30px;
  line-height: 30px;
  font-weight: normal;
}

.p32_v2 {
  font-family: var(--secondary-font);
  font-size: 32px;
  line-height: 32px;
}

.p24_v2 {
  font-family: var(--secondary-font);
  font-size: 24px;
  line-height: 24px;
}

.p14 {
  font-family: var(--secondary-font);
  font-size: 14px;
  line-height: 14px;
}

.small {
  font-family: var(--main-font);
  font-size: 13px;
  line-height: 20px;
}

.smallBold {
  @extend .small;
  font-weight: bold;
}

p[color='error'] {
  color: red;
}

.h3heading {
  font-family: var(--main-font);
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
}

.fileInput {
  box-shadow: var(--card-elevation);
  max-width: 460px !important;
  border-radius: 8px;
  button svg {
    --svg-color: var(--primary-color) !important;
  }
}

.input {
  padding-right: 0px !important;

  input,
  textarea {
    padding: 0px 16px;
    border: 1px solid #c8c8c8 !important;
    font-size: 0.65em !important;
    border-radius: 8px;
    &::placeholder {
      font-weight: bold;
      opacity: 0.5;
    }
  }
}

*[color='light'] {
  --text-default-color: #b9b9b9;
}

.textBase {
  a {
    color: var(--primary-color);
    text-decoration: none;
    font-weight: bold;
  }
}

*[color='primary'] {
  --text-default-color: var(--digital-blue);
}

.collapsable:global(.bulky) {
  box-shadow: var(--card-elevation);
  border-radius: 16px;
  > :first-child {
    padding: 48px;
    box-shadow: var(--card-elevation);
    cursor: pointer;
    border-radius: 16px;
    display: flex;
    justify-content: space-between;
  }
  > :last-child {
    max-width: initial;
    > * {
      padding: 24px 48px;
    }
  }
}

.spacing_8 {
  height: 8px;
}
.spacing_16 {
  height: 16px;
}
.spacing_32 {
  height: 32px;
}
.spacing_40 {
  height: 40px;
}
