.root {
  height: 100%;
  padding: 160px - 16px 160px - 32px;
  overflow: auto;
}

@media screen and (max-width: 640px) {
  .root {
    padding: 24px 0px;
  }
}
