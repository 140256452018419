$baseAssetWidth: 200;
$baseExpandedWidth: 302;
$baseAssetHeight: 440;
$baseAssetMargin: 10;
$marginBottomOfTitle: 16px;
$basePadding: 16px;

$maximumNumberOfLinesOfText: 1em;
$minimumNumberOfLinesOfText: 1em;
$paddingSum: $basePadding * 2;
$oneLineHeight: 1.7em;

@value baseExpandedWidth #{$baseExpandedWidth};
@value baseAssetWidth #{$baseAssetWidth};

.asset {
  width: #{$baseAssetWidth}px;
  min-width: #{$baseAssetWidth}px;
  height: #{$baseAssetWidth}px;
  margin: #{$baseAssetMargin}px;
  background-position: top;
  background-size: #{$baseAssetWidth}px auto;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 16px;
  border: 2px solid #fff0;
  overflow: hidden;
  transition: background-size 250ms linear, height 250ms linear,
    width 250ms linear, min-width 250ms linear, border-color 250ms linear;
  box-shadow: var(--card-elevation);
  background-repeat: no-repeat;
  --text-default-color: var(--text-dark);

  > :last-child {
    background-color: white;
    height: 0px;
    transition: height 250ms linear, padding 250ms linear;
    padding: 0px $basePadding;
    display: flex;
    flex-direction: column;
  }

  &:not(.cvm) > :last-child {
    > * > :first-child {
      margin-bottom: $marginBottomOfTitle;
    }
    > :nth-child(2) {
      max-height: 24px * 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    > :last-child {
      margin-top: auto;
    }
  }

  &.expanded {
    width: #{$baseExpandedWidth}px;
    height: #{$baseAssetHeight}px;
    background-size: #{$baseExpandedWidth}px auto;
    border-color: #e0e0e0;

    > :last-child {
      $maximumNumberOfLinesOfText: 4em;
      $minimumNumberOfLinesOfText: 3em;
      $paddingSum: $basePadding * 2;
      padding: $basePadding $basePadding;
      height: 148px;
    }
  }

  &.short {
    width: #{$baseExpandedWidth}px;
    min-width: #{$baseExpandedWidth}px;
    height: calc(#{$baseExpandedWidth}px + 1em);
    background-size: #{$baseExpandedWidth}px auto;

    > :last-child {
      padding: $basePadding $basePadding;
      height: calc($paddingSum + $oneLineHeight);
      > *:not(button) {
        height: $oneLineHeight;
        overflow: hidden;
      }
    }
  }

  &.cvm {
    $cvmCardHeight: 420;
    $cvmHeadHeight: 144;
    cursor: initial;
    > :first-child {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-size: cover;
      height: #{$cvmHeadHeight}px;
      position: relative;
      &::before {
        content: ' ';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        background-color: #0008;
      }
      > * {
        z-index: 1;
      }
      > :first-child {
        flex: 1;
        display: flex;
        gap: 12px;
        padding: 24px;
        align-items: center;
        width: 100%;
        img {
          width: 20%;
        }
        > :last-child {
          width: 80%;
        }
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      flex: 1;
    }

    > :last-child {
      align-items: flex-start;
      .row {
        display: flex;
        justify-content: space-between;
        align-self: stretch;
        padding: 17px 16px;
        margin: 0px -16px;
        border-bottom: 1px solid rgba(226, 226, 226, 1);
        transition: padding 250ms linear;
        overflow: hidden;
        > :last-child {
          text-align: right;
        }
      }
      button {
        margin-top: 16px;
      }
    }

    &.expanded {
      height: #{$cvmCardHeight}px;
      > :last-child {
        padding-top: 0px;
        height: #{$cvmCardHeight - $cvmHeadHeight}px;
      }
    }

    &.expanded,
    &.short {
      > :last-child {
        padding-top: 0px;
      }
    }

    &.short {
      > :last-child {
        height: calc($paddingSum + 42px);
      }

      .row {
        max-height: 0px;
        padding: 0px 16px;
        border-bottom-width: 0px;
      }
    }

    .equity {
      background-color: rgba(38, 46, 140);
      color: var(--text-default-color);
    }
    .fixed,
    .receivable {
      background-color: rgba(97, 135, 125);
      color: var(--text-default-color);
    }

    .goalProgress {
      background-color: #0000004d;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      width: 100%;
      padding: 8px 24px;
      &.complete {
        background: linear-gradient(90deg, #43ed7d -1.32%, #4b4b4b 79.8%);
      }
      > * {
        display: flex;
        justify-content: space-between;
        > * {
          white-space: nowrap;
        }
        > :nth-child(2) {
          color: rgba(67, 237, 125, 1);
        }
      }
    }
  }
}
