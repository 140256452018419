.root {
  display: flex;
  justify-content: space-between;
  &.v {
    flex-direction: column;
    align-items: flex-start;
  }
  &.h {
    flex-direction: row;
    align-items: center;
  }
  img {
    width: 100%;
  }
}
.float {
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  backdrop-filter: blur(3px);
  margin-right: 24px;
}
.avatar {
  margin-right: 8px;
}
.info,
.userInfo {
  width: 100%;
  overflow: hidden;
}
