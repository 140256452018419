.heading,
.items {
  display: flex;
  align-items: center;
  width: 100%;
}

.heading {
  justify-content: space-between;
  font-size: 24px;
  > :first-child {
    display: flex;
    align-items: center;
    font-size: 1em !important;
    line-height: 1em !important;
    transition: font-size 250ms linear, line-height 250ms linear;
    margin-left: 50px;
    @media screen and (max-width: 640px) {
      margin-left: 12px;
    }

    > :first-child {
      --digital-blue: red;
      margin-right: 0.45em;
      transition: margin-right 250ms linear;

      > :first-child {
        font-size: 1em !important;
        transition: font-size 250ms linear;
      }

      &:active {
        cursor: move;
      }
    }
  }
}

.root {
  width: 100%;
  background-image: var(--halo-section);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

$padding: 40px;

.items {
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  overflow: auto;
  margin-bottom: 103px - $padding;
  padding: $padding - 7px 0px $padding 0px;
}

.item {
  transition: right 250ms ease-in, transform 250ms linear;
}

.hoverable .item {
  &:hover {
    transform: scale(1.02);
  }
}

.pagination {
  margin-top: -16px - $padding;
  margin-bottom: 16px;
  display: flex;
  justify-content: center;
  max-width: 100vw;
}

.itemsContainer {
  position: relative;
}
