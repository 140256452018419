$baseHeight: 393px;
$padding: 48px;
$outsideLeak: 24px;
$maxImgWidth: 300px;
$paddingSm: 24px;
$breakpoint: $maxImgWidth + 2 * $padding;

@value breakInto #{$breakpoint};

.root {
  padding: 24px 0px;
  width: 100%;
  .wrapper {
    display: flex;
    align-items: flex-start;
    min-height: $baseHeight;
    background: linear-gradient(#303030, #321664);
    gap: 96px 72px;
    border-radius: 16px;
    padding: 32px 40px 40px 40px;
    img {
      align-self: center;
      max-height: $baseHeight + 24px * 2;
      border-radius: 24px;
      object-fit: cover;
      max-width: 400px;
      margin: -24px - 32px 0px -24px - 40px 0px;
    }
    img.shimmer,
    img.error {
      align-self: stretch;
      height: $baseHeight + 24px * 2;
      width: $maxImgWidth;
      object-fit: cover;
    }
    .shimmer,
    .error {
      opacity: 0.6;
      max-width: fit-content;
      &:not(img) {
        border-radius: 8px;
      }
    }
    img.error {
      background-color: var(--error-color);
    }

    @media screen and (max-width: $breakpoint * 2) {
      flex-wrap: wrap;
    }

    @media screen and (max-width: $breakpoint) {
      margin-top: -32px * 2;
      padding: $paddingSm;
      img,
      img.error,
      img.shimmer {
        width: 100%;
      }
    }
  }
}
