.root {
  padding-top: 120px;
  padding-bottom: 60px;
  --container-max-width: 1600px;
  > :first-child {
    display: flex;
    flex-direction: column;
    height: 100%;
    > :first-child {
      flex: 1;
      padding: 8px var(--overflow-padding, 8px);
      margin: 0px var(--overflow-margin, -8px);
      padding-bottom: 28px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--screen-padding);
    padding-top: 8px;
  }
  .intro {
    max-width: 660px;
    padding: 0px 100px;
    display: flex;
    flex-direction: column;
    > :not(div) {
      max-width: 430px;
    }
    .benefitLabel {
      font-family: var(--secondary-font);
      font-size: 18px;
      line-height: 31px;
      letter-spacing: 0px;
      text-align: left;
    }

    svg {
      height: 69px;
      margin-top: 8px;
    }
    > svg {
      align-self: flex-start;
      margin-top: auto;
      margin-bottom: 24px;
    }
  }

  .featureCard {
    max-width: 486px;
    width: 100%;
    margin-right: 22px;
    box-shadow: var(--card-elevation);
    background: var(--glass-bg);
    border-radius: 20px;
    overflow: hidden;

    main {
      padding: 60px;
      text-align: center;

      .feature {
        display: block;
        background-color: var(--main-bg);
        font-family: var(--secondary-font);
        font-size: 16px;
        font-weight: bold;
        line-height: 20px;
        text-align: center;
        padding: 16px 0px;
        margin-bottom: 12px;
        border-radius: 10px;
      }
      ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        margin-top: 30px;
        li {
          color: var(--text-default-color);
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .intro > .introTitle p {
    font-size: 32px;
  }
  .intro header {
    background-size: auto 100% !important;
  }
}

@media screen and (max-height: 960px) {
  .root {
    padding-top: 48px;
    padding-bottom: 24px;
    .featureCard {
      max-width: 400px;
      header {
        height: 200px;
        padding: 32px 0px;
        background-size: cover;
        .title {
          margin-bottom: 0px;
        }
        p {
          font-size: 16px;
        }
      }
      main {
        padding: 24px;
      }
    }
  }
}

@media screen and (max-width: 640px) {
  .root {
    padding-top: 24px;
    .intro {
      padding: 0px 24px;
      flex-shrink: 1;
      svg {
        margin-top: auto;
        height: 32px;
        margin-left: auto;
      }
    }
    .actions {
      flex-wrap: wrap-reverse;
      > :last-child {
        margin-left: auto;
      }
      button {
        margin: auto;
      }
    }
    .featureCard {
      header {
        height: 200px;
        padding: 32px 0px;
        p {
          font-size: 16px;
        }
        .title {
          margin-bottom: 0px;
        }
        .description {
          max-width: 300px;
          font-size: 16px;
        }
      }
      main {
        padding: 12px 24px;
        > :first-child {
          margin-top: 0px;
        }
        .feature {
          padding: 12px 0px;
          margin-bottom: 8px;
        }
        ul {
          margin-top: 8px;
        }
      }
    }
  }
}
