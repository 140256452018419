.main {
  display: flex;
  gap: 10px 48px;
  > :first-child {
    max-width: 500px;
  }
  > :last-child {
    padding: 32px 0px;
    flex: 1;
  }
}
@media screen and (max-width: 650px) {
  .main {
    flex-wrap: wrap;
  }
  .investmentInfo {
    flex-wrap: wrap;
  }
}

.investmentCard {
  --progress-bar-bg: var(--secondary-background);
  .investmentInfo {
    display: flex;
    gap: 24px;
    > * {
      flex: 1;
    }
  }
  .goalProgress {
    position: relative;
    > :first-child {
      margin-bottom: 12px;
    }
    svg,
    &::after {
      top: -4px;
      position: absolute;
    }
    svg {
      width: 24px;
      margin-left: -12px;
      --svg-color: var(--primary-color);
      left: var(--goal-progress);
    }
    &::after {
      content: ' ';
      height: 12px;
      width: 2px;
      right: 0px;
      background-color: var(--primary-color);
    }
  }
}

.ribbon {
  background-color: var(--primary-color);
  padding: 32px var(--screen-padding);
  > * {
    display: flex;
    justify-content: center;
    gap: 24px 72px;
    text-align: center;
    flex-wrap: wrap;
  }
}

.sections > * {
  margin-bottom: 80px;
}
.documents {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.tabs {
  position: sticky;
  top: 16px;
  z-index: 1;
}

.founderContainer {
  padding: 24px 0px;
  > * {
    display: flex;
    justify-content: space-around;
    > * {
      flex-shrink: 0;
      width: 320px;
      > div {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;
      }
      button {
        margin-top: auto;
        svg {
          height: 1em;
        }
      }
    }
  }
}
.forum {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  p {
    max-width: 352px;
  }
}

.forumDialog {
  padding-left: 0px;
  padding-right: 0px;
  padding-top: 100px;
  overflow: auto;

  .forumModal {
    height: 100%;
    width: 100%;
    > .pad {
      margin: 16px 32px;
      margin-bottom: 0px;
    }
  }
}

.back {
  margin-top: 24px;
  margin-bottom: 24px;
}

.banner {
  padding: 0px;
  .main {
    column-gap: 0px;
    > :last-child {
      max-width: 650px;
      padding: 18px;
    }
  }
}
