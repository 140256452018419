.root {
  background: linear-gradient(92.12deg, #b69ef0 -0.46%, #88cbd5 87.43%),
    linear-gradient(0deg, #d9d9d9, #d9d9d9);
  padding: 125px 0px;
  > * {
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    > :first-child {
      max-width: 440px;
    }
    .models {
      margin-left: auto;
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      .model {
        border-radius: 16px;
        overflow: hidden;
        box-shadow: var(--card-elevation);
        > :first-child {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 64px 84px;
          text-align: center;
          height: 238px;
          gap: 12px;
          > :last-child {
            margin: auto;
          }
          > * {
            flex-shrink: 0;
          }
        }
        padding: 0px;
        max-width: 410px;
        height: 430px;
        > :last-child {
          padding: 36px 42px;
        }
      }
    }
    .model:first-child > :first-child {
      background-color: #3b3755;
    }
    .model:last-child > :first-child {
      background-color: #385170;
    }
  }
}
