.toggler {
  padding: 12px;
  border-radius: 36px / 50%;
  background-color: #00000033;
  .tabContainer {
    --tab-guide-color: var(--text-default-color);
    --tab-selected-color: var(--main-bg);
    @media screen and (max-width: 650px) {
      flex-wrap: wrap;
    }
    .tab {
      text-align: center;
      color: var(--digital-blue);
      font-weight: bold;
      text-transform: uppercase;
    }
  }

  &.large {
    background-color: var(--main-bg);
    .tabContainer > :last-child {
      border-radius: 2em;
    }
    .tab {
      padding: 16px 40px;
    }
  }
}
