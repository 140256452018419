.effect {
  background-image: url('../../../assets/svg/fractal.svg');
  background-repeat: no-repeat;
  background-size: auto 100%;
  border-radius: 16px;
  @media screen and (min-width: 1300px) {
    background-size: 100% auto;
  }
  padding: 0px 68px;
  margin-top: 48px;
  border-radius: 16px;
  position: relative;
  height: 480px;

  align-items: center;
  flex-direction: row;
  display: flex;
  gap: 50px;
  > :last-child {
    position: absolute;
    bottom: 60px;
    right: 80px;
  }
  > :nth-child(2) {
    max-width: 500px;
  }
  > * {
    flex-shrink: 0;
    flex: 1;
  }
  > *:not(:last-child):not(header) {
    min-width: 300px;
  }
  > header {
    min-width: 420px;
  }

  @media screen and (max-width: 640px) {
    padding: var(--screen-padding);
    > header {
      min-width: auto;
    }
  }
  @media screen and (max-width: 1000px) {
    height: initial;
    flex-wrap: wrap;
    padding-bottom: 130px;
    padding-top: 48px;
  }
}

.features {
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
  > * {
    width: 300px;
    min-height: 300px;
    border-radius: 16px;
    background: linear-gradient(var(--angle), #312f3a 20%, #9747ff 110%);
    background-size: 120%;
    background-position: center;
    padding: 24px 32px;

    > :first-child {
      margin-bottom: 16px;
    }
  }
}

.moreFeatures {
  > * {
    display: flex;
    overflow: auto;
    gap: 20px;
    cursor: pointer;
    margin-bottom: 24px;
    > * {
      flex-shrink: 0;
    }
  }
}

.case {
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: flex-start;
  > :first-child {
    max-width: 400px;
    margin-right: auto;
  }
  > :last-child {
    max-width: 100%;
    height: 100%;
    max-height: 700px;
    align-self: flex-end;
  }
}
