.root {
  border-radius: 8px;
  display: inline-flex;
  padding: 8px 20px;
  align-items: center;
  justify-content: center;
  &.default {
    background-color: var(--secondary-background);
  }
  &.primary {
    background-color: var(--primary-color);
  }
  &.small {
    border-radius: 14px;
    padding: 4px 20px;
  }
}
