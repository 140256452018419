$topbarSpacing: 94px;

.challenges {
  > * {
    width: 100%;
  }
}

.banner {
  padding-bottom: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  --text-default-color: #fff;
  svg.logo {
    position: absolute;
    height: 75%;
    top: 125px - $topbarSpacing;
    max-height: 360px;
    max-width: 90vw;
    background-color: linear-gradient(
      329.33deg,
      #dcd1ff 16.49%,
      #cbfafd 95.69%
    );
  }

  > :not(svg):not(.lava) {
    z-index: 1;
    max-width: 600px;
    text-align: center;
  }

  > :nth-child(2) {
    margin-bottom: 76px * 0.6;
  }

  > :nth-child(3) {
    max-width: 340px;
    margin: 26px;
  }

  button {
    margin-top: 106px * 0.6;
    min-width: 217px;
  }

  .lava {
    width: 100vw;
    height: 50vh;
    max-width: var(--container-max-width);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    border-radius: 32px;
    overflow: hidden;
    background: radial-gradient(
      68.01% 282.67% at 31.99% 50%,
      var(--digital-blue) 0%,
      #1f3a63 67.33%,
      #1b0b28 88.02%
    );
    --digital-blue: #04c0d2;
  }
  @media screen and (orientation: portrait) {
    padding: 16px 16px 68px 16px;
    .lava {
      padding: 32px;
      width: calc(100vw - 32px);
      height: initial;
    }
  }
}

.asset,
.gallery,
.store {
  width: 302px;
  min-width: 302px;
  margin-right: 26px;
  border-radius: 16px;
  overflow: hidden;
  min-height: 302px;
}

.store {
  height: 400px;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  background-size: auto 100%;
  transition: background-size 250ms linear;
  box-shadow: var(--card-elevation);
  &:hover {
    background-size: auto 110%;
  }

  > * {
    padding: 21px;

    * {
      text-align: left !important;
    }
  }
}

.partnership {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 102px !important;
  padding: 0px 16px;

  > * {
    min-width: 100%;
  }

  p {
    max-width: 340px;
  }

  @media screen and (min-width: (400px + 16px * 2)) {
    > * {
      min-width: 400px;
    }
  }

  > :first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background-repeat: no-repeat;
    background-size: auto 400px;
    background-position: center;
    overflow: visible;
    width: 100%;
    max-width: 500px;
    .logoBg {
      min-width: 400px;
      position: absolute;
      height: 100%;
    }

    .logo {
      height: 412px;
      width: 100%;

      @media screen and (max-width: 450px) {
        height: 300px;
      }
    }

    .badge {
      position: absolute;
      height: 220px;
    }
  }

  > :last-child {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    > :nth-child(2) {
      margin-bottom: 60px;
      margin-top: 26px;
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;
  padding: 70px 16px 185px 16px;
  align-items: center;
  width: 100%;

  > *:not(:last-child) {
    text-align: center;
  }

  > :first-child {
    margin: 26px 0px;
  }

  > :nth-child(2) {
    margin-bottom: 58px;
  }

  > :last-child {
    width: 100%;
    max-width: 700px;
    padding: 48px 16px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
    display: flex;

    > * {
      width: 100%;
      max-width: 550px;
      margin: auto;
    }
  }
}

.banner ~ div {
  flex-shrink: 0;
}

.center {
  text-align: center;
}
.transition {
  min-height: auto;
}

.homeBanner {
  padding: 48px 0px;
  min-height: 60vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 24px;
  background-position: center;
  background-size: 100vw auto;
  @media screen and (max-width: 640px) {
    background-size: auto 100%;
  }
  > :first-child {
    align-items: center;
    display: flex;
    flex-direction: column;
    p {
      text-align: center;
      max-width: 450px;
      margin-bottom: 40px;
    }
  }
}
