.contact {
  background-image: url('../../assets/svg/fractal.svg');
  background-repeat: no-repeat;
  background-size: 100% auto;
  @media screen and (max-width: 1200px) {
    background-size: auto 100%;
  }
  background-position: center;
  padding: 48px 0px;
  > * {
    max-width: 500px;
    text-align: center;
  }
}
