.incognito {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;

  @media screen and (orientation: portrait) {
    flex-direction: column;
  }
  > :last-child {
    max-width: 300px;
  }

  svg {
    overflow: visible;
  }
}
