.container {
  display: flex;
  > * {
    padding: 14px;
    background-color: #151824;
    border-radius: 16px;
    margin-right: 16px;
    display: flex;
    cursor: pointer;
    * {
      color: white;
    }
  }
}
